// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCyAIhR5Uf7fo36FTF1Pgf500DRlHt6QEA",
  authDomain: "gasbillnew4.firebaseapp.com",
  databaseURL: "https://gasbillnew4-default-rtdb.firebaseio.com",
  projectId: "gasbillnew4",
  storageBucket: "gasbillnew4.appspot.com",
  messagingSenderId: "1072789721550",
  appId: "1:1072789721550:web:f026a508f994b4050a9b9f",
  measurementId: "G-9GZGN1H73K"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };